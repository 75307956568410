<template>
  <div style="margin: -15px -15px;background-color: #fff;">
    <a-spin :spinning="spinning">
    <div class="video-top">
      <div>
        <span>选择年份：</span>
        <a-date-picker
            v-model="year"
            mode="year"
            v-decorator="['year']"
            placeholder="请输入年份"
            format="YYYY"
            class="input"
            :open="yearShowOne"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
      </div>
      <div>
        <span>专家姓名：</span>
        <a-input  @pressEnter="lookBtn" placeholder="请输入专家姓名" class="input" v-model="expertName"/>
      </div>
      <div>
        <span>视频标题：</span>
        <a-input  @pressEnter="lookBtn" placeholder="请输入视频标题" class="input" v-model="videoTitle"/>
      </div>
      <div>
        <span>类型：</span>
        <a-select
            style="width: 150px"
            class="input"
            @change="typeChange"
            v-model="stateType"
            placeholder="请选择">
          <a-select-option
              v-for="type in stateTypeList"
              :value="type.state">
            {{type.title}}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <a-button @click="lookBtn" type="primary">查询</a-button>
        <a-button @click="restBtn" style="margin-left: 15px">重置</a-button>
      </div>
    </div>
    <div class="video-tab">
      <a-tabs v-model="tabValue" @change="callback">
        <a-tab-pane key="2" tab="云ICUAPP点播视频"></a-tab-pane>
        <a-tab-pane key="1" tab="大会点播回放"></a-tab-pane>
      </a-tabs>
    </div>
    <div class="video-content">
      <div class="video-content-l">
        <a-alert v-if="tabValue === '1'" type="info" show-icon>
          <template slot="message">
            <span>只显示添加了[点播回放]功能的大会</span>
          </template>
        </a-alert>
        <!--  大会搜索 -->
        <a-input-search
            v-if="tabValue === '1'"
            style="margin: 10px;width: 90%"
            placeholder="请输入大会名称"
            enter-button="搜索"
            size="small"
            v-model="LiveTitleValue"
            @search="onSearch"
        />
        <!-- 菜单选择  大会列表-->
        <a-menu
            v-if="tabValue === '1'"
            style="width: 100%"
            v-model="liveId"
            mode="inline"
        >
          <a-menu-item
              v-for="(live,index) in leftLiveList"
              @click="selectLiveStatus(live)"
              :key="live.id">
            <a-tooltip>
              <template slot="title">
                {{live.title}}
              </template>
              <span style="overflow: hidden;text-overflow:ellipsis; white-space: nowrap;">{{live.title}}</span>
            </a-tooltip>
          </a-menu-item>
        </a-menu>
        <!-- 菜单选择  app视频列表-->
        <a-menu
            v-else
              style="width: 100%"
              v-model="selectId"
              mode="inline"
          >
            <a-menu-item
                v-for="(item,index) in leftTempletes"
                @click="selectStatus(item)"
                :key="item.code">
              <a-tooltip>
                <template slot="title">
                  {{item.title === '云ICU'?'全部栏目':item.title}}
                </template>
                <span style="overflow: hidden;text-overflow:ellipsis; white-space: nowrap;">{{item.title === '云ICU'?'全部栏目':item.title}}</span>
              </a-tooltip>
            </a-menu-item>
          </a-menu>
        <!--  大会分页 -->
        <a-pagination
            v-if="tabValue === '1'"
            style="display: flex;justify-content: right"
            show-less-items
            size="small"
            :default-current="LivePageNo"
            :total="LiveTotal"
            @change="LiveOnChange" />
      </div>
      <div class="video-content-r">
        <div class="r-btn" v-if="tabValue === '1' ? true : selectId[0] !== 'YUN_ICU'">
          <a-button v-if="tabValue === '1' ? true : selectId[0] !== 'YUN_ICU'" @click="newProject" type="primary">新增专题</a-button>
          <a-button v-if="tabValue === '1' ? true : selectId[0] !== 'YUN_ICU'" @click="newVideo" style="margin-left: 15px">新增单视频</a-button>
        </div>
        <a-alert v-else type="info" show-icon class="b-btn">
          <template slot="message">
            <span style="cursor: pointer">新增专题、单视频，上移下移操作请先选择栏目~</span>
          </template>
        </a-alert>
        <div>
          <a-table
              :scroll="{ x: 1500 }"
              :pagination="false"
              :columns="columns"
              :data-source="data"
              :row-key="record => record.id">
<!--            <span slot="sort" slot-scope="text, record">-->
<!--              <a @click="upDownBtn(record,'up')">上移</a>-->
<!--              <a @click="upDownBtn(record,'down')" style="margin-left: 8px">下移</a>-->
<!--            </span>-->
            <span slot="name" slot-scope="text, record">
<!--              类型:VOD_SINGLE-点播单视频--视频 MIX_SET_TOPIC-混合内容--专题-->
              <span v-if="record.type === 'MIX_SET_TOPIC'">
<!--              <span v-if="record.type === 'VOD_SET_TOPIC'">-->
                <a-button size="small" type="primary">专题</a-button>
                <a style="margin-left: 10px" @click="titleBtn(record.id)">{{ text }}</a>
              </span>
              <span v-if="record.type === 'VOD_SINGLE'" >
                <a-button style="color: #4a9bfa;background-color: #dcf0f6;border: 1px solid #4a9bfa" size="small">视频</a-button>
                <a style="margin-left: 10px" @click="openUrl(record.id)">{{ text }}</a>
              </span>

              <span v-if="record.type !== 'MIX_SET_TOPIC' && record.type !== 'VOD_SINGLE'">{{text}}</span>
            </span>
<!--            <span slot="AssociationMeeting" slot-scope="text, record">-->
<!--              <a v-if="record.conventionIdList !== null ? countLenght(record.conventionIdList):0 !== 0" @click="openAssociation(record.id,'关联会议')">-->
<!--                {{record.conventionIdList !== null ? countLenght(record.conventionIdList):0}}-->
<!--              </a>-->
<!--              <span v-else>{{record.conventionIdList !== null ? countLenght(record.conventionIdList):0}}</span>-->
<!--            </span>-->
<!--            <span slot="AssociatedColumn" slot-scope="text, record">-->
<!--              <a v-if="record.columnList !== null ? countLenght(record.columnList):0 !== 0" @click="openAssociation(record.id,'关联栏目')">-->
<!--                {{record.columnList !== null ? countLenght(record.columnList):0}}-->
<!--              </a>-->
<!--              <span v-else>{{record.columnList !== null ? countLenght(record.columnList):0}}</span>-->
<!--            </span>-->
            <span slot="conCount" slot-scope="text, record">
              <a @click="openAssociation(record.id,'关联会议')">
                {{record.conCount}}
              </a>
<!--              <span v-else>{{record.conventionIdList !== null ? countLenght(record.conventionIdList):0}}</span>-->
            </span>
            <span slot="colCount" slot-scope="text, record">
              <a @click="openAssociation(record.id,'关联栏目')">
                {{record.colCount}}
              </a>
<!--              <span v-else>{{record.columnList !== null ? countLenght(record.columnList):0}}</span>-->
            </span>
              <span slot="isOpen" slot-scope="text, record">
               <a-switch
                   v-model="record.isOpen === 1?true:false"
                   checked-children="开"
                   un-checked-children="关"
                   @change="SwitchBtn(record.isOpen === 0?1:0,record.id)"
                   default-checked />
            </span>
            <span slot="action" slot-scope="text, record">

              <div v-if="tabValue === '2'">
               <a-popconfirm
                   :title="record.isRelease ? '是否确认下架?':'是否确认上架?'"
                   ok-text="Yes"
                   cancel-text="No"
                   @confirm="soldOut(record,record.isRelease ? 0:1)"
               >
                   <a v-if="record.isRelease" href="#">下架</a>
                   <a v-else href="#">上架</a>
                 </a-popconfirm>
                <a style="margin-left: 5px" @click="promotions(record)">推广</a>
<!--                <a style="margin-left: 5px" @click="soldOut(record,0)" v-if="record.isRelease">下架</a>-->
<!--                <a style="margin-left: 5px" @click="soldOut(record,1)" v-else>上架</a>-->
                <a-dropdown style="margin-left: 5px">
                <a-menu slot="overlay" >
                  <a-menu-item v-if="record.type === 'MIX_SET_TOPIC'" @click="table_restBtn(record.id)" key="1">
                    修改
                  </a-menu-item>
                  <a-menu-item :disabled="!(selectId[0]!== 'YUN_ICU')" @click="openDel(record)" key="2">
                     <span>删除</span>
                  </a-menu-item>
                  <a-menu-item key="3">
                     动态
                  </a-menu-item>
                </a-menu>
                <a class="ant-dropdown-link" > 更多 <a-icon type="down" /></a>
              </a-dropdown>
              </div>
              <div v-if="tabValue === '1'">
                 <a-popconfirm
                     style="margin-right: 5px"
                     :title="record.isRelease ? '是否确认下架?':'是否确认上架?'"
                     ok-text="Yes"
                     cancel-text="No"
                     @confirm="soldOut(record,record.isRelease ? 0:1)"
                 >
                   <a v-if="record.isRelease" href="#">下架</a>
                   <a v-else href="#">上架</a>
                 </a-popconfirm>
                <a style="margin-right: 5px" @click="conVodUp(record.itemId)">上移</a>
                <a style="margin-right: 5px" @click="conVodDown(record.itemId)">下移</a>
                <!--  专题有修改，视频没有              -->
                <a v-if="record.type === 'MIX_SET_TOPIC'" @click="table_restBtn(record.id)" style="margin-right: 5px">修改</a>
<!--                <a-popconfirm-->
<!--                    title="是否确认删除?"-->
<!--                    ok-text="Yes"-->
<!--                    cancel-text="No"-->
<!--                    @confirm="confirmDelLive(record)"-->
<!--                ><a href="#">删除</a></a-popconfirm>-->
                <a @click="openDel(record)">删除</a>
              </div>
            </span>
          </a-table>

          <div style="margin-top: 10px;align-items: center;display: flex;justify-content: space-between">
            <span style="font-size: medium;color: #929292">共 {{total}} 条记录 第 {{ pageNum }} / {{ Math.ceil(total / pageSize) }} 页</span>
            <a-pagination
                v-model="pageNum"
                :total="total"
                show-size-changer
                :page-size="pageSize"
                @change="onChange"
                @showSizeChange="tableChange"
                show-quick-jumper
                style="float: right"
            >
            </a-pagination>
          </div>
        </div>
      </div>
    </div>
<!--------------------------------------------------对话框------------------------------------------->
<!--  -->
    <a-drawer
        :title="vodTitle"
        :width="700"
        :visible="visible_upVideo"
        :body-style="{ paddingBottom: '80px' }"
        @close="close_video"
    >
      <a-form-model
          ref="content_ruleForm"
          :model="videoForm"
          :rules="content_video_rules"
          :label-col="labelColLive"
          :wrapper-col="wrapperCol">
        <a-form-model-item prop="surface_plot" label="列表封面图">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  id="up_img_surface_plot"
                  style="width: 100%;height:100%;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="content_surface_plot_image"
                  ref="content_surface_plot_original_image" />
            </div>
            <div
                class="upload_LiveBroadcastL">
              <img
                  v-if="videoForm.surface_plot"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="videoForm.surface_plot"
              />
            </div>
          </div>
          <div style="color: #a1a1a1;width: 500px">注：建议尺寸1920*520，宽度不能超过1920，大小不超过2M</div>
        </a-form-model-item>
        <a-form-model-item prop="coverUrl" label="详情封面图">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  id="up_img_surface_plot_original"
                  style="width: 100%;height:100%;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="content_surface_plot_original_video"
                  ref="content_surface_plot_video_image" />
            </div>
            <div
                class="upload_LiveBroadcastX">
              <img
                  v-if="videoForm.coverUrl"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="videoForm.coverUrl"
              />
            </div>
          </div>
          <div style="color: #a1a1a1;width: 500px">注：建议尺寸1920*1080，宽度不能超过1920，大小不超过2M</div>
        </a-form-model-item>
        <a-form-model-item  prop="title" label="标题">
          <a-input
              v-model="videoForm.title"
              style="width: 300px"
              placeholder="请输入标题"
          />
        </a-form-model-item>
        <a-form-model-item label="关键字">
          <a-input
              v-model="videoForm.keyName"
              style="width: 300px"
              placeholder="请输入关键字"
          />
        </a-form-model-item>
        <a-form-model-item v-if="tabValue === '1'">
          <template slot="label">
              <span>关联会议
              <a-tooltip placement="top">
            <template slot="title">
              <span>选择关联会议后，即可将选择会议上传的点播视频自动添加到该专题下</span>
            </template>
            <a-icon type="exclamation-circle" />
          </a-tooltip></span>
          </template>
          <!-- 关联会议         -->
          <a-select
              disabled
              v-model="videoForm.AssociationMeet"
              show-search
              placeholder="请选择"
              option-filter-prop="children"
              style="width: 300px"
          >
            <a-select-option
                v-for="(selectM,index) in selectHosListVideo"
                :key="index"
                :value="selectM.id">
              {{selectM.title}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="vodTitle === '新增专题'" prop="specialist" label="发布到云ICUapp">
          <!-- 大会新增时关联会议不能选择，但显示会议名称 可以选择栏目开关和栏目         -->
          <a-switch
              v-if="tabValue === '1'"
              style="margin-right: 5px"
              :checked="videoForm.specialistSwitch === 1?true:false"
              checked-children="是"
              un-checked-children="否"
              @change="openSwitchBtn(videoForm.specialistSwitch === 0?1:0)"
              default-checked />
          <a-select
              mode="multiple"
              v-if="videoForm.specialistSwitch && tabValue === '1'"
              v-model="videoForm.specialist"
              show-search
              placeholder="请选择栏目"
              option-filter-prop="children"
              style="width: 300px"
          >
            <a-select-option
                v-for="(select_spe,index) in leftTemplete"
                :key="index"
                :value="select_spe.code">
              <span>{{select_spe.title}}</span>
            </a-select-option>
          </a-select>
          <!-- app视频 新增时 关联会议不显示  可以选择栏目栏目 开关开着         -->
          <a-switch
              v-if="tabValue === '2'"
              disabled
              style="margin-right: 5px"
              :checked="true"
              checked-children="是"
              un-checked-children="否"
              default-checked />
          <a-select
              mode="multiple"
              v-if="tabValue === '2'"
              v-model="videoForm.specialist"
              show-search
              placeholder="请选择栏目"
              option-filter-prop="children"
              style="width: 300px"
          >
            <a-select-option
                v-for="(select_spe,index) in leftTemplete"
                :key="index"
                :value="select_spe.code">
              <span v-if="select_spe.code !== 'YUN_ICU'">{{select_spe.title}}</span>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="include" label="介绍">
          <div style="border: 1px solid #ccc;min-height: 300px;width: 500px">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                style="overflow-y: hidden;"
                v-model="html"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
            />
          </div>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="close_video">
          取消
        </a-button>
        <a-button
            style="margin-left: 10px"
            type="primary" @click="onsubmit_video">
          确定
        </a-button>
      </div>
    </a-drawer>
<!--      查看专题视频-->
    <a-drawer
        title="查看专题视频"
        :width="1400"
        :visible="visible_Check_video"
        :body-style="{ paddingBottom: '80px' }"
        @close="close_Check_video"
    >
      <div style="width: 98%;margin: auto;">
        <div class="addVideo">添加视频</div>
        <div style="margin-bottom: 20px;display: flex;">
          <div style="width: 33%">
            <a-select
                size="large"
                v-model="selectHosModel"
                show-search
                placeholder="选择医学会议"
                option-filter-prop="children"
                style="width:90%"
                @popupScroll="popupScroll_model"
                @search="select_model"
                @change="onSearchHos"
            >
              <a-select-option
                  v-for="(selectHos,index) in selectHosList"
                  :key="index"
                  @click="onSearch_model_hos(selectHos.vodCount)"
                  :value="selectHos.id">
                {{selectHos.title}}
              </a-select-option>
            </a-select>
          </div>
          <div style="width: 33%">
            <a-alert type="info" show-icon style="width:90%">
              <template slot="message">
                <span>共搜索到 {{videoNum}} 个视频</span>
                <a-popconfirm
                    title="是否确认导入?"
                    ok-text="是"
                    cancel-text="否"
                    style="margin-left: 10px"
                    @confirm="confirmImport"
                ><a href="#">确认导入</a></a-popconfirm>
<!--                <a style="margin-left: 10px" @click="confirmImport">确认导入</a>-->
              </template>
            </a-alert>
          </div>
        </div>
        <div style="display: flex;margin-bottom: 20px">
          <div style="width: 33%;">
            <a-input-search
                v-model="search_model"
                style="width: 90%"
                placeholder="请输入标题关键字搜索视频库"
                enter-button="搜索"
                size="large"
                @search="onSearch_model1"
            />
          </div>
          <div style="width: 33%">
            <a-input-search
                v-model="article"
                style="width: 90%"
                placeholder="请输入标题关键字搜索文章"
                enter-button="搜索"
                size="large"
                @search="onSearch_article"
            />
          </div>
        </div>
        <div>
          <a-table :columns="columns_video" :data-source="data_video"  :pagination="false">
            <span slot="img" slot-scope="text, record">
              <img v-if="confirmChoices === '视频库' && record.coverImg" style="width: 100px;height: auto" :src="record.coverImg" alt="">
              <img v-if="confirmChoices === '文章' && record.cover" style="width: 100px;height: auto" :src="record.cover[0]" alt="">
            </span>
            <span slot="hospital" slot-scope="text, record">
              <span>{{record.expertName}} - {{record.expertHospital}}</span>
            </span>
            <span slot="action" slot-scope="text, record">
              <a v-if="record.stated === '0'" @click="confirmChoice(record.id)">选择</a>
              <span style="color: #8c8a8a" v-else>选择</span>
            </span>
          </a-table>

          <div style="margin-top: 10px;align-items: center;display: flex;justify-content: space-between">
            <span style="font-size: medium;color: #929292">共 {{data_model_count_video}} 条记录 第 {{ videoPageNo }} / {{ Math.ceil(data_model_count_video / videoPageSize) }} 页</span>
            <a-pagination
                v-model="videoPageNo"
                :total="data_model_count_video"
                show-size-changer
                :page-size="videoPageSize"
                @change="videoPageNoChange1"
                @showSizeChange="videoPageSizeChange1"
                show-quick-jumper
                style="float: right"
            >
            </a-pagination>
          </div>


        </div>
        <div class="specialCatalog"><span>专题目录</span></div>
        <div style="display: flex">
          <span style="margin-top: 5px">标题：</span>
          <a-input v-model="titleVal" placeholder="请输入" style="width: 300px"/>
          <a-button @click="getZhuanTableList" style="margin-left: 15px" type="primary">查询</a-button>
        </div>
<!--        <div style="margin-top: 10px;margin-bottom: 10px;margin-left: 45px">-->
<!--          共搜索到{{ data_model_count }}个视频-->
<!--        </div>-->
        <div class="child-content">
          <a-table :columns="columns_model" :data-source="data_model"  :pagination="false">
            <span slot="sort" slot-scope="text, record">
              <a @click="upDownBtnZhuan(record.id,'up')">上移</a>
              <a @click="upDownBtnZhuan(record.id,'down')" style="margin-left: 8px">下移</a>
            </span>
            <span slot="itemTitle" slot-scope="text, record">
             <div style="display: flex">
               <a-button v-if="record.itemType === 'VIDEO'" style="color: #4a9bfa;background-color: #dcf0f6;border: 1px solid #4a9bfa;" size="small">视频</a-button>
               <a-button v-if="record.itemType === 'ARTICLE'" style="color: #35c924;background-color: #eff5ee;border: 1px solid #91e887;" size="small">文章</a-button>
               <span style="margin-left: 5px">{{text}}</span>
             </div>
            </span>
            <span slot="hospital" slot-scope="text, record">
              {{record.expertName}} - {{record.expertHospital}}
            </span>
            <span slot="action" slot-scope="text, record">
              <a-popconfirm
                  title="是否确认删除?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="confirm_del(record.id)"
              ><a href="#">删除</a></a-popconfirm>

               <a-popconfirm
                   style="margin-left: 5px"
                   :title="record.isRelease ? '是否确认下架?':'是否确认上架?'"
                   ok-text="Yes"
                   cancel-text="No"
                   @confirm="soldOutContent(record,record.isRelease ? 0:1)"
               >
                   <a v-if="record.isRelease" href="#">下架</a>
                   <a v-else href="#">上架</a>
                 </a-popconfirm>
            </span>
          </a-table>
<!--          <div style="width: 100%;margin-top: 15px;margin-bottom: 30px">-->
<!--            <div style="float: right;margin-right: 20px">-->
<!--              <a-pagination-->
<!--                  show-quick-jumper-->
<!--                  :current="vodPageNo"-->
<!--                  :total="data_model_count"-->
<!--                  :show-total="total => `共 ${data_model_count} 个`"-->
<!--                  @change="vodPageNoChange" />-->
<!--            </div>-->
<!--          </div>-->


          <div style="margin-top: 10px;align-items: center;display: flex;justify-content: space-between">
            <span style="font-size: medium;color: #929292">共 {{data_model_count}} 条记录 第 {{ vodPageNo }} / {{ Math.ceil(data_model_count / vodPageSize) }} 页</span>
            <a-pagination
                v-model="vodPageNo"
                :total="data_model_count"
                show-size-changer
                :page-size="vodPageSize"
                @change="vodPageNoChange"
                @showSizeChange="vodTableChange"
                show-quick-jumper
                style="float: right"
            >
            </a-pagination>
          </div>

        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="close_Check_video">
          取消
        </a-button>
      </div>
    </a-drawer>

    <a-drawer
          :title="videoTitleVod"
          :width="1000"
          :visible="visible_add_video"
          :body-style="{ paddingBottom: '80px' }"
          @close="close_add_video"
      >
        <div style="width: 100%;margin: auto;">
          <div style="width: 100%">
              <a-input-search
                  v-model="search_model"
                  style="width: 70%"
                  placeholder="请输入标题关键字搜索视频库"
                  enter-button="搜索"
                  size="large"
                  @search="onSearch_model"
              />
            </div>
          <div style="margin-top: 20px">
            <a-table :columns="columns_video" :data-source="data_videoVod"  :pagination="false">
            <span slot="img" slot-scope="text, record">
              <img style="width: 100px;height: auto" :src="record.coverImg" alt="">
            </span>
              <span slot="hospital" slot-scope="text, record">
              <span>{{record.expertName}} - {{record.expertHospital}}</span>
            </span>
              <span slot="duration" slot-scope="text, record">
              <span>{{ current_times(text) }}</span>
            </span>
              <span slot="action" slot-scope="text, record">
              <a v-if="record.stated === '0'" style="margin-right: 15px" @click="insSingleByManageId(record.id)">选择</a>
              <span v-else style="color: #8c8a8a;margin-right: 15px">选择</span>
<!--              <a @click="ColumnCodeBtn(record.id)" v-if="tabValue === '1' && record.stated === '0'">选择并发布到App</a>-->
<!--              <span v-if="tabValue === '1' && record.stated === '1'" style="color: #8c8a8a">选择并发布到App</span>-->
            </span>
            </a-table>

            <div style="margin-top: 10px;align-items: center;display: flex;justify-content: space-between">
              <span style="font-size: medium;color: #929292">共 {{videoCountLive}} 条记录 第 {{ videoPageNoLive }} / {{ Math.ceil(videoCountLive / videoPageSizeLive) }} 页</span>
              <a-pagination
                  v-model="videoPageNoLive"
                  :total="videoCountLive"
                  show-size-changer
                  :page-size="videoPageSizeLive"
                  @change="LiveChange"
                  @showSizeChange="LiveChangeSize"
                  show-quick-jumper
                  style="float: right"
              >
              </a-pagination>
            </div>

          </div>
        </div>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button  @click="close_add_video">
            关闭
          </a-button>
        </div>
      </a-drawer>
    <!--   推广 -->
    <a-drawer
          title="推广"
          :width="500"
          :visible="promotionsVisible"
          :body-style="{ paddingBottom: '80px' }"
          @close="promotionsClose"
      >
      <a-spin :spinning="promotionSpin">
        <div style="margin:20px">
         <a-table  :pagination="false"  :row-key="record => record.id" :columns="uniAppColumns" :data-source="uniAppData" bordered>
           <span slot="is_open" slot-scope="text, record">
              <a-switch v-model="record.is_open" checked-children="是" un-checked-children="否" @click="minaRecommend(record)" default-checked />
            </span>
         </a-table>
       </div>
        <div style="margin:20px">
            <a-table :pagination="false" :columns="appColumns" :data-source="appData" :row-key="record => record.code" bordered>
          <span slot="is_open" slot-scope="text, record">
            <a-switch v-if="record.title == '社区首页'" v-model="record.is_open" checked-children="是" un-checked-children="否" @click="communityListSwitch(record.is_open,record)" default-checked />
            <a-switch v-else v-model="record.is_open" checked-children="是" un-checked-children="否" @click="AppListSwitch(record.is_open,record)" default-checked />
          </span>
            </a-table>
        </div>

      </a-spin>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="promotionsClose">
            关闭
          </a-button>
        </div>
      </a-drawer>
      <a-modal width="500px" v-model="delVisible" @ok="table_delBtn">
        <p style="font-size: 16px;margin-top: 10px">
          <a-icon style="color: red;font-size: 25px" type="close-circle" />
          {{ isDel }}
        </p>
      </a-modal>
<!--      选择栏目-->
      <a-modal title="选择栏目" v-model="ColumnSelection" @ok="ColumnBtn" @cancel="cancelBtn">
        <span style="margin-right: 5px">栏目:</span>
        <a-select
            mode="multiple"
            show-search
            option-filter-prop="children"
            style="width: 300px"
            v-model="columnCode"
            placeholder="请选择">
          <a-select-option
              v-for="select_o in leftTemplete"
              :value="select_o.code">
            {{select_o.title}}
          </a-select-option>
        </a-select>
      </a-modal>
<!--      关联栏目，关联大会 -->
      <a-modal
          :footer="null"
          :title="titleAssociation"
          v-model="association"
          @cancel="associationCancel">
        <div>
        <!-- 关联栏目选择-->
          <div v-if="titleAssociation === '关联栏目'" style="margin-bottom: 10px">
            <span>关联栏目：</span>
            <a-select
                mode="multiple"
                v-model="associatedColumn"
                show-search
                placeholder="请选择栏目"
                option-filter-prop="children"
                style="width: 300px;margin-right: 5px"
            >
              <a-select-option
                  v-for="(selectL,index) in leftTemplete"
                  :key="index"
                  :value="selectL.code"
                  :disabled="selectL.isShow">
                <span>{{selectL.title}}</span>
              </a-select-option>
            </a-select>
            <a-button @click="addSelectLM" type="primary">添加</a-button>
          </div>
        <!-- 关联大会选择-->
          <div v-if="titleAssociation === '关联会议'" style="margin-bottom: 10px">
            <span>关联大会：</span>
            <a-select
                v-model="associationMeet"
                show-search
                placeholder="请选择"
                option-filter-prop="children"
                style="width: 300px;margin-right: 5px"
                @change="changeAssociationMeet"
            >
              <a-select-option
                  v-for="(selectD,index) in AssociationList"
                  :key="index"
                  :value="selectD.id">
                {{selectD.title}}
              </a-select-option>
            </a-select>
            <a-button @click="addSelectDH" type="primary">添加</a-button>
          </div>
        </div>
        <div
            style="margin-bottom: 5px"
            v-for="(listA,index) in associationValue"
            :key="index">
          <span>{{index+1}}、{{ listA.title }}</span>
          <a-icon
              @click="delAssociation(titleAssociation,listA)"
              style="font-size: 18px;color: red;cursor: pointer;margin-left: 5px" type="close-circle" />
        </div>
      </a-modal>
    </a-spin>
  </div>
</template>
<script>
import moment from "moment";
import {update} from "../../../utils/update";
import {communityLinks} from "@/utils/liveLink";
import {current_date} from '@/utils/currentDate'
// import E from "wangeditor";
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import {
  columnVodsMoveUpDown,
  delZhuanVods,
  delVods,
  delVodTrue,
  delAppPromotion,
  getCaseRoomVods,
  get_column_vods,
  get_demand_playback_one_list,
  get_video_one_list,
  getKeyWordVods,
  get_vods_num,
  getVodsTopic,
  getAppPromotion,
  getColumnVodsList,
  getVodsList,
  getcommunityPromotion,
  postChooseVods,
  postLeadVods,
  postLeadVodsMoveUpDown,
  post_lead_vods_two,
  postAppPromotion,
  postcommunityPromotion,
  putVodsList,
  postVodsList,
  postInsSingleByManageId,
  getArticleVods,
  postChooseArticle,
  delVod,
  getColumnByVodId,
  selConventionTitleByVodId,
  getVodsListL,
  getVodsListLD,
  delVodL,
  delVodD,
  colVodUp,
  colVodDown,
  conVodDown,
  conVodUp,
  insVodConOrCol,
  delVodConList,
  delVodColList,
  isTuiGuang,
  upOrDow,
  upItemIsRelease
} from "@/service/MedicalConference_y";
import {CaseLive, CaseLiveNew, getLiveList} from "@/service/MedicalConference";
import {postminaRecommend,getminaRecommend,delminaRecommend} from "@/service/Recruiting";
import axios from "axios";
import TcVod from "vod-js-sdk-v6";
const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    width: '30%',
    scopedSlots: { customRender: 'name' },
    ellipsis: true,
    // fixed: 'left'
  },{
    title: '专家',
    dataIndex: 'expertName',
    key: 'expertName',
    // scopedSlots: { customRender: 'expertName' },
    width:'20%',
  },{
    title: '上传时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    // ellipsis: true,
  },{
    title: '创建人',
    dataIndex: 'person',
    key: 'person',
  },{
    title: '动态',
    dataIndex: 'isOpen',
    key: 'isOpen',
    scopedSlots: { customRender: 'isOpen' },
  },
  // {
  //   title: '关联大会',
  //   scopedSlots: { customRender: 'AssociationMeeting' },
  // },{
  //   title: '关联栏目',
  //   scopedSlots: { customRender: 'AssociatedColumn' },
  // },
  {
    title: '关联大会',
    scopedSlots: { customRender: 'conCount' },
  },{
    title: '关联栏目',
    scopedSlots: { customRender: 'colCount' },
  },
  {
    title: '操作',
    key: 'action',
    width: 200,
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  },
];
// const columnsOne = [
//   {
//     title: '标题',
//     dataIndex: 'title',
//     key: 'title',
//     width:'30%',
//     scopedSlots: { customRender: 'name' },
//     ellipsis: true,
//   },{
//     title: '专家',
//     dataIndex: 'expertName',
//     key: 'expertName',
//     // scopedSlots: { customRender: 'expertName' },
//     width:'20%',
//   },{
//     title: '上传时间',
//     dataIndex: 'createdTime',
//     key: 'createdTime',
//     // ellipsis: true,
//   },{
//     title: '创建人',
//     dataIndex: 'person',
//     key: 'person',
//   },{
//     title: '排序',
//     dataIndex: 'sort',
//     key: 'sort',
//     width:'12%',
//     scopedSlots: { customRender: 'sort' },
//   },{
//     title: '操作',
//     key: 'action',
//     width:'15%',
//     scopedSlots: { customRender: 'action' },
//   },
// ];
// const columnsNew = [
//   {
//     title: '标题',
//     dataIndex: 'title',
//     key: 'title',
//     width:'30%',
//     scopedSlots: { customRender: 'name' },
//     ellipsis: true,
//   },{
//     title: '专家',
//     dataIndex: 'expertName',
//     key: 'expertName',
//     // scopedSlots: { customRender: 'expertName' },
//     width:'20%',
//   },{
//     title: '上传时间',
//     dataIndex: 'createdTime',
//     key: 'createdTime',
//     // ellipsis: true,
//   },{
//     title: '创建人',
//     dataIndex: 'person',
//     key: 'person',
//   },{
//     title: '操作',
//     key: 'action',
//     width:'15%',
//     scopedSlots: { customRender: 'action' },
//   },
// ];
const columns_model = [
  {
    title: '标题',
    dataIndex: 'itemTitle',
    key: 'itemTitle',
    width:'30%',
    ellipsis:true,
    scopedSlots: { customRender: 'itemTitle' },
  },{
    title: '专家',
    dataIndex: 'expertName',
    key: 'expertName',
    scopedSlots: { customRender: 'hospital' },
    width:'30%',
  },{
    title: '上传时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    width:'15%',
  },{
    title: '创建人',
    dataIndex: 'createdName',
    key: 'createdName',
    width:'10%',
  },{
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    width:'10%',
    scopedSlots: { customRender: 'sort' },
  },{
    title: '操作',
    key: 'action',
    width:'10%',
    scopedSlots: { customRender: 'action' },
  },
];
const columns_video = [
  {
    title: '封面图',
    dataIndex: 'coverImg',
    key: 'coverImg',
    scopedSlots: { customRender: 'img' },
  },{
    title: '标题',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '专家',
    dataIndex: 'expertName',
    key: 'expertName',
    scopedSlots: { customRender: 'hospital' },
    width:'30%',
  },{
    title: '操作',
    key: 'action',
    width:'20%',
    scopedSlots: { customRender: 'action' },
  },
];
const editorConfig = { // JS 语法
  MENU_CONF: {}
};
const signature = '';
// 修改 uploadImage 菜单配置
editorConfig.MENU_CONF['uploadImage'] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let formData = new FormData()
    formData.append('file', file)
    // file 即选中的文件,自己实现上传，并得到图片 url alt href
    axios.post('https://yun-new-admin-api.yunicu.com/api/admin/common/update', formData).then((res) => {
      if(res.data.code === 0){
        insertFn(res.data.data.url)// 最后插入图片
      }
    })
  }
}
// 修改 uploadVideo 菜单配置
editorConfig.MENU_CONF['uploadVideo'] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let mediaFile = file;
    //获取签名
    axios.get('https://yun-new-admin-api.yunicu.com/v3/getUgcUploadSign').then((res) => {
      if (res.data.code === 0) {
        const getSignature = () => {
          return res.data.data.signature;
        }
        const tcVod = new TcVod({
          getSignature: getSignature //上传签名的函数
        })
        const uploader = tcVod.upload({
          mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
        })
        uploader.done().then(res => {
          insertFn(res.video.url)// 进行视频处理：转码和截图
        })
      }
    })
  }
}
export default {
  name:'videoOnDemand',
  components:{Editor, Toolbar},
  data(){
    return{
      // labelCol: { span: 8},
      // wrapperCol: { span: 18 },
      communityIs_open:'',
      isDel:'',
      titleAssociation:'',
      associatedColumn:[],
      associationMeet:undefined,
      AssociationList:[],
      association:false,
      associationValue:null,
      delIdTable:null,
      delIdTableList:null,
      delVisible:false,
      pageNum:1,
      pageSize:10,
      tableListId:'',
      total:0,
      spinning:false,
      data:[],
      titleVal:'',

      videoPageNo:1,
      videoPageSize:10,
      data_model_count_video:0,

      videoPageNoLive:1,
      videoPageSizeLive:10,
      videoCountLive:1,

      vodPageNo:1,
      vodPageSize:10,
      data_model:[],
      data_model_count:0,
      columns,
      // columnsNew,
      // columnsOne,
      columns_model,
      columns_video,
      data_video:[
        // {
        //   img:'https://img2.baidu.com/it/u=3202947311,1179654885&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
        //   title:'1111',
        //   expertName:'冯',
        // }
      ],
      confirmChoices:'',
      confirmChoicesValue:'',
      data_videoVod:[
        // {
        //   img:'https://img2.baidu.com/it/u=3202947311,1179654885&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
        //   title:'1111',
        //   expertName:'冯',
        //   duration:1,
        // }
      ],
      yearShowOne:false,
      // year:current_date(),
      year:'',
      videoTitle:'',
      selectId:[],
      liveId:[],
      leftLiveList:[],
      keyWordVod_pageNo:1,
      keyWordVod_pageSize:10,
      expertName:'',
      listId:null,
      leftTemplete:[],
      leftTempletes:[],
      vodTitle:'',
      visible_upVideo:false,
      videoForm:{
        surface_plot:'',
        coverUrl:'',
        title:'',
        specialist:[],
        include:'',
        keyName:'',
        AssociationMeet:undefined,
        specialistSwitch:0,
      },
      content_video_rules:{
        surface_plot: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        coverUrl: [{ required: true, message: '详情封面图', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        specialist: [{ required: true, message: '请选择栏目', trigger: 'blur' }],
        include: [{ required: true, message: '请输入介绍', trigger: 'blur' }],
      },
      stateType:undefined,
      stateTypeList:[
        {
          title:'全部',
          state:''
        },{
          title:'专题',
          state:'MIX_SET_TOPIC'
        },{
          title:'视频',
          state:'VOD_SINGLE'
        },
      ],
      labelCol: { span: 4},
      labelColLive: { span: 5},
      wrapperCol: { span: 14 },
      editor: null,
      html: '',
      toolbarConfig: { },
      editorConfig,
      mode: 'default', // or 'simple'

      // editor_menus:[
      //   'head', // 标题
      //   'bold', // 粗体
      //   'fontSize', // 字号
      //   'fontName', // 字体
      //   'italic', // 斜体
      //   'underline', // 下划线
      //   'strikeThrough', // 删除线
      //   'foreColor', // 文字颜色
      //   'backColor', // 背景颜色
      //   'link', // 插入链接
      //   'list', // 列表
      //   'justify', // 对齐方式
      //   'quote', // 引用
      //   'emoticon', // 表情
      //   'image', // 插入图片
      //   'table', // 表格
      //   'video', // 插入视频
      //   'code', // 插入代码
      //   'undo', // 撤销
      //   'redo', // 重复
      //   'fullscreen' // 全屏
      // ],
      visible_Check_video:false,
      // CaseLiveList:[],
      // CaseLiveList_page_no:1,
      // CaseLiveList_title:'',
      oneList:{},
      selectHosModel:undefined,
      videoNum:0,
      vodId:null,
      selectHosList:[],
      selectHosListVideo:[],
      selectHosList_page_no:1,
      selectHosList_title:'',
      search_model:'',
      article:'',
      keyWordVod:[],
      keyWordArticle:[],
      keyWordVodIsShow:false,
      promotionsVisible:false,
      appColumns:[
        {
          title: 'APP',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      appData:[],
      columnListApp:[],
      promotionId:null,
      subContentType:null,
      promotionSpin:false,
      uniAppColumns:[
        {
          title: '小程序',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      uniAppData:[
        {
          id:1,
          title:'推荐页（首页）',
          is_open:false
        }
      ],
      // communityCode:'',
      visible_add_video:false,
      tabValue:'2',

      LivePageNo:1,
      LiveTitleValue:'',
      LiveTotal:0,
      videoTitleVod:'',
      ColumnSelection:false,
      manageId:null,
      columnCode:[],

      Association_pageNo:1,
      Association_title:'',
      listIdA:null,
    }
  },
  created() {
    // this.columns = this.columnsOne
    // this.getLiveList()
    this.getCodeList()
    this.getLeftList()
    this.$store.dispatch('setManageHeaderTitle','点播视频管理')
  },
  methods:{
    async minaRecommend(record){
      if(record.is_open === true){
        const data = {
          contentId:this.promotionId,
          contentType:'VOD',
          subContentType:this.subContentType,
        }
        const response = await postminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }else{
        const data = {
          contentId:this.promotionId,
          contentType:'VOD',
          subContentType:this.subContentType,
        }
        const response = await delminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    //获取栏目code列表
    async getCodeList() {
      const response = await getColumnVodsList()
      if (response.code === 0) {
        for (let i=0;i<response.data.length;i++){
          if(response.data[i].code !== 'YUN_ICU'){
            this.leftTemplete.push({
              code: response.data[i].code,
              delStatus: response.data[i].delStatus,
              id: response.data[i].id,
              title:response.data[i].title
            })
          }
        }
      }else {
        this.$message.warning(response.message)
      }
    },

    //获取大会议左侧列表
    async getLiveList() {
      let data = {
        page_no:this.LivePageNo,
        page_size:8,
        title:this.LiveTitleValue
      }
      const response = await getLiveList(data)
      if(response.code === 0){
        this.leftLiveList = response.data
        // if(!this.liveId[0]){
          this.liveId[0] = response.data[0].id
        // }
        this.LiveTotal = response.count
        await this.getList()
      }else {
        this.$message.warning(response.message)
      }
    },
    //左侧大会列表搜索框
    onSearch(value){
      this.LivePageNo = 1
      this.LiveTitleValue = value
      this.getLiveList()
    },
    LiveOnChange(pageNumber){
      this.LivePageNo = pageNumber
      this.getLiveList()
    },

    async getLeftList() {
      const response = await getColumnVodsList()
      if (response.code === 0) {
        this.leftTempletes = response.data
        if(!this.selectId[0]){
          this.selectId[0] = response.data[0].code
        }
        await this.getList()
      }else {
        this.$message.warning(response.message)
      }
    },
    //获取列表  根据tab选择获取列表
    async getList() {
      this.spinning = true
      //  获取app列表
      let data = {}
      if(this.tabValue === '1'){
        data = {
          conventionId:this.liveId[0],
          // column:this.selectId[0],
          title:this.videoTitle,
          // year:this.year.slice(0,4),
          year:this.year,
          //类型
          type:this.stateType,
          expertName:this.expertName,
          // id:this.listId,
          page_no:this.pageNum,
          page_size:this.pageSize,
        }
        const response = await getVodsListLD(data)
        if(response.code === 0){
          this.data = response.data
          this.total = response.count
        }else {
          this.$message.warning(response.message)
        }
      }else if(this.tabValue === '2'){
        data = {
          column:this.selectId[0],
          title:this.videoTitle,
          // year:this.year.slice(0,4),
          year:this.year,
          //类型
          type:this.stateType,
          expertName:this.expertName,
          // id:this.listId,
          page_no:this.pageNum,
          page_size:this.pageSize,
        }
        const response = await getVodsListL(data)
        if(response.code === 0){
          this.data = response.data
          this.total = response.count
        }else {
          this.$message.warning(response.message)
        }
      }
        // const response = await getVodsList(data)
        // if(response.code === 0){
        //   this.data = response.data
        //   this.total = response.count
        // }else {
        //   this.$message.warning(response.message)
        // }
      this.spinning = false
    },
    //获取关联会议
    async getHosVideoList() {
      let data = {
        id:this.liveId[0],
        page_no:1,
        page_size:10
      }
      const response = await CaseLiveNew(data)
      if (response.code === 0) {
        this.selectHosListVideo  = response.data.rows
      } else {
        this.$message.warning(response.message)
      }
    },
    async getAssociationMeet() { //关联会议
      let data = {
        page_no:this.Association_pageNo,
        page_size:10,
        title:this.Association_title
      }
      const response = await getLiveList(data)
      if(response.code === 0){
        this.AssociationList = response.data
      }else {
        this.$message.warning(response.message)
      }
    },
    async getHosList(pageNum,name) {
      let data = {
        name:name,
        page_no:pageNum,
        page_size:10
      }
      const response = await getCaseRoomVods(data)
      if (response.code === 0) {
        let list  = response.data
        for (let i=0;i<list.length;i++){
          this.selectHosList.push({
            id: list[i].id,
            title: list[i].title,
            vodCount:list[i].vodCount
          })
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    popupScroll_model(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.selectHosList_page_no++
        this.getHosList(this.selectHosList_page_no,this.selectHosList_title)
      }
    },
    //鼠标搜索
    select_model(value){
      //首先清空数组，否则会数据重复
      this.selectHosList = []
      this.selectHosList_title = value
      this.getHosList('',this.selectHosList_title)
    },
    onChange(pageNumber){
      this.pageNum = pageNumber
      this.getList()
    },
    tableChange(pageNumber,pageSize){
      this.pageSize = pageSize
      this.getList()
    },

    vodPageNoChange(pageNo){
      this.vodPageNo = pageNo
      this.getZhuanTableList()
    },
    vodTableChange(pageNumber,pageSize){
      this.vodPageSize = pageSize
      this.getZhuanTableList()
    },
    upDownBtn(list, moveUpDown) {
      this.moveUpDown_list(list.id,moveUpDown)
    },
    async moveUpDown_list(id, type) {
      let data = {
        id:id,
        type:type
      }
      const response = await columnVodsMoveUpDown(data)
      if (response.code === 0) {
        this.$message.success("操作成功！")
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    // async CaseLive_list(page_no,title) {
    //   const response = await CaseLive(page_no,10,'',title)
    //   if (response.code === 0) {
    //     // this.CaseLiveList = response.data.rows
    //     let list  = response.data.rows
    //     for (let i=0;i<list.length;i++){
    //       this.CaseLiveList.push({
    //         id: list[i].id,
    //         title: list[i].title,
    //       })
    //     }
    //   } else {
    //     this.$message.warning(response.message)
    //   }
    // },
    // popupScroll_live(e){
    //   const {target} = e
    //   const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
    //   const clientHeight = target.clientHeight //滑动最低高度
    //   if(scrollHeight < clientHeight + 2){
    //     this.CaseLiveList_page_no++
    //     this.CaseLive_list(this.CaseLiveList_page_no,this.CaseLiveList_title)
    //   }
    // },
    // //鼠标搜索
    // select_live(value){
    //   //首先清空数组，否则会数据重复
    //   this.CaseLiveList = []
    //   this.CaseLiveList_title = value
    //   this.CaseLive_list(1,this.CaseLiveList_title)
    // },

    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }else {
        this.yearShowOne = false;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.year = value;
      if(this.year){
        this.year = moment(this.year).format('YYYY')
      }
      this.getList()
    },
    lookBtn(){
      this.getList()
    },
    restBtn(){
      // this.year = current_date();
      this.year = '';
      this.expertName = '';
      this.videoTitle = '';
      this.stateType = undefined;
      this.getList()
    },
    selectStatus(list){
      this.selectId[0] = list.code;
      // if(this.selectId[0] === 'YUN_ICU'){
      //   this.columns = this.columnsNew
      // }else {
      //   this.columns = this.columnsOne
      // }
      this.pageNum = 1
      this.pageSize = 10
      this.getList()
    },
    selectLiveStatus(list){
      this.liveId[0] = list.id;
      this.pageNum = 1
      this.pageSize = 10
      this.getList()
    },
    table_restBtn(id) {
      this.rulesFun()
      this.tableListId = id
      //获取关联会议
      this.getHosVideoList()
      //获取单个视频详情
      this.getOneList(id)

      this.vodTitle = '编辑专题'
      this.visible_upVideo = true;
      // this.$nextTick(() => {
      //   if (this.editor === null) {
      //     this.create_editor_config()
      //   } else {
      //     this.editor.destroy()
      //     this.create_editor_config()
      //   }
      // })
    },
    async getOneList(id) {
      // const response = await get_demand_playback_one_list(id)
      const response = await get_video_one_list(id)
      if (response.code === 0) {
        // 模拟 ajax 请求，异步渲染编辑器
        setTimeout(() => {
          this.html = data.intro;
        }, 500)
        // this.oneList = response.data
        this.oneList = response.data[0]

        let form = this.videoForm
        let data = response.data[0]

        form.surface_plot = data.cover[0]
        form.coverUrl = data.cover[1]
        form.title = data.title

        form.specialist = data.columnList ? data.columnList:[]

        form.specialistSwitch = data.columnList ? 1:0
        //介绍
        // if(data.intro){
        //   this.editor.txt.html(data.intro)
        // }

        form.keyName = data.keyword
        // form.AssociationMeet = data.conventionId
        form.AssociationMeet = this.liveId[0]
      } else {
        this.$message.warning(response.message)
      }
    },
    // confirmDelLive(row){
    //   this.delIdTable = row.id
    //   this.tableListId = row.id
    //   this.delIdTableList = row
    //   this.table_delBtn()
    // },
    countLenght(o){
      var t = typeof o;
      if(t == 'string'){
        return o.length;
      }else if(t == 'object'){
        var n = 0;
        for(var i in o){
          n++;
        }
        return n;
      }
      return false;
    },
    async openDel(row) {
      const response = await isTuiGuang(row.id)
      if (response.code === 0) {
        //删除前判断是否被推广
        if(response.data){ //被推广
          this.$message.info("该视频已被推广，请先关闭推广在进行删除操作！")
        }else { //没有被推广
          if(row.colCount + row.conCount === 1){
            this.isDel = '该数据删除之后，以往产生的浏览量将不会保留，是否确认执行删除操作？'
          }else {
            this.isDel = '是否确认删除！'
          }
          this.delVisible = true;
          this.delIdTableList = row
          this.delIdTable = row.itemId
          this.tableListId = row.id
        }

      } else {
        this.$message.warning(response.message)
      }
    },
    //上下架
    async soldOut(row, isRelease) {
      let data = {
        id: row.id,
        isRelease: isRelease
      }
      const response = await upOrDow(data)
      await this.getList()
      if (response.code === 0) {
        this.$message.success((isRelease ? '上架':'下架') + '成功~')
      } else {
        this.$message.warning(response.message)
      }
    },
    async table_delBtn() {
      // let data = {}
      if (this.tabValue === '1') { //大会删除
        if(this.isDel === '该数据删除之后，以往产生的浏览量将不会保留，是否确认执行删除操作？'){
        //  为1
          const response = await delVodTrue(this.tableListId)
          if (response.code === 200) {
            await this.getList()
            this.$message.success(response.message)
          } else {
            this.$message.warning(response.message)
          }
        }else {
          // 不为1
          const response = await delVodD(this.delIdTable)
          if (response.code === 200) {
            await this.getList()
            this.$message.success(response.message)
          } else {
            this.$message.warning(response.message)
          }
        }

      } else if (this.tabValue === '2') {  //app栏目删除
       if(this.isDel === '该数据删除之后，以往产生的浏览量将不会保留，是否确认执行删除操作？'){
         //  为1
         const response = await delVodTrue(this.tableListId)
         if (response.code === 200) {
           await this.getList()
           this.$message.success(response.message)
         } else {
           this.$message.warning(response.message)
         }
       }
       else {
         // 不为1
         const response = await delVodL(this.delIdTable)
         if (response.code === 200) {
           await this.getList()
           this.$message.success(response.message)
         } else {
           this.$message.warning(response.message)
         }
       }

      }
      // this.delApi(data)
      this.tableListId = null
      this.delVisible = false
      this.delIdTable = ''
    },
    // 删除接口
    async delApi(data) {
      const response = await delVod(data)
      if (response.code === 200) {
        await this.getList()
        this.$message.success(response.message)
      } else {
        this.$message.warning(response.message)
      }
    },
    // table_delBtn() {
    //   //点击删除按钮
    //   if(this.tabValue === '1'){  //大会删除
    //
    //     /**
    //      * 1.会议id有，栏目无
    //      * 2.会议id有，栏目有
    //      *
    //      * 执行删除接口
    //      */
    //
    //     if(this.delIdTableList.conventionId){
    //       let columnList = this.delIdTableList.columnList
    //       let data = {}
    //       // 2  CONVENTION
    //       if(columnList){
    //         data = {
    //           id:this.delIdTableList.id,
    //           type:'CONVENTION'
    //         }
    //       }else {  // 1  DELETE
    //         data = {
    //           id:this.delIdTableList.id,
    //           type:'DELETE'
    //         }
    //       }
    //       this.delApi(data)
    //     }
    //   }else if(this.tabValue === '2'){  //栏目app  删除
    //     /**
    //      * 1.栏目有一个
    //      *     ----有会议id
    //      *     ----没有会议id
    //      *     执行删除接口
    //      *
    //      * 2.栏目有多个，又或者没有关联会议id
    //      *     执行修改接口
    //      */
    //     let columnListApp = this.delIdTableList.columnList
    //     let dataApp = {}
    //     if(columnListApp){
    //       if(columnListApp.length === 1){  //  栏目有一个
    //         if(this.delIdTableList.conventionId){  //  有会议id
    //           dataApp = {
    //             id:this.delIdTableList.id,
    //             type:'COLUMN'
    //           }
    //         }else {  //没有会议id
    //           dataApp = {
    //             id:this.delIdTableList.id,
    //             type:'DELETE'
    //           }
    //         }
    //         this.delApi(dataApp)
    //
    //       }else if(columnListApp.length > 1){  //  栏目有多个
    //         //删除当前专题下的栏目code,调用修改接口
    //         let index = columnListApp.indexOf(this.selectId[0]);
    //         columnListApp.splice(index, 1)
    //
    //         let dataRest = {
    //           id:this.tableListId,
    //           columnList:columnListApp,//栏目代码
    //         }
    //         this.restDianVode(dataRest)
    //         this.getList()
    //         // this.$message.success('删除成功~')
    //       }
    //     }
    //   }
    //   this.tableListId = null
    //   this.delVisible = false
    //   this.delIdTable = ''
    // },
    // // 删除接口
    // async delApi(data) {
    //   const response = await delVod(data)
    //   if (response.code === 200) {
    //     await this.getList()
    //     // this.$message.success('删除成功~')
    //     this.$message.success(response.message)
    //   } else {
    //     this.$message.warning(response.message)
    //   }
    // },
    close_video(){
      this.tableListId = ''
      this.selectHosList = []
      this.selectHosListVideo = []
      this.selectHosList_page_no = 1
      this.selectHosList_title = ''
      // this.CaseLiveList = []
      // this.CaseLiveList_page_no = 1
      // this.CaseLiveList_title = ''
      this.$refs.content_ruleForm.resetFields();
      this.videoForm.specialist = []
      this.videoForm.keyName = ''
      this.videoForm.AssociationMeet = undefined
      this.videoForm.specialistSwitch = 0
      this.visible_upVideo = false
    },
    onsubmit_video(){
      this.videoForm.include = this.html;
      this.$refs.content_ruleForm.validate(async valid => {
        if (valid) {
          let form = this.videoForm
        //  判断新增还是修改
          if(this.tableListId){
          //  修改
            let data = {}
            if(this.tabValue === '1'){
              data = {
                //大会修改
                id:this.tableListId,
                title:form.title,//标题
                cover:[form.surface_plot,form.coverUrl],//封面
                intro:form.include,//介绍
                // columnList:form.specialist.length ? form.specialist :null,//栏目代码
                conventionId:form.AssociationMeet ? form.AssociationMeet:null,//会议id
                keyword:form.keyName,
              }
            }else if(this.tabValue === '2'){
              //app修改
              data = {
                id:this.tableListId,
                title:form.title,//标题
                cover:[form.surface_plot,form.coverUrl],//封面
                intro:form.include,//介绍
                // columnList:form.specialist.length ? form.specialist :null,//栏目代码
                keyword:form.keyName,
              }
            }
            await this.restDianVode(data)
          }else {
            //  新增
            let dataAdd = {}
          //  大会
            if(this.tabValue === '1'){
              dataAdd = {
                title:form.title,//标题
                cover:[form.surface_plot,form.coverUrl],//封面
                intro:form.include,//介绍
                // expertId: "专家id",
                // columnCode:form.specialist,//栏目代码
                columnList:form.specialist.length ? form.specialist :null,//栏目代码
                conventionId:form.AssociationMeet ? form.AssociationMeet:null,//会议id
                keyword:form.keyName,
              }
            }else if(this.tabValue === '2'){
              //  app
              dataAdd = {
                title:form.title,//标题
                cover:[form.surface_plot,form.coverUrl],//封面
                intro:form.include,//介绍
                columnList:form.specialist.length ? form.specialist :null,//栏目代码
                // conventionId:form.AssociationMeet,//会议id
                keyword:form.keyName,
              }
            }
            await this.addDianVode(dataAdd)
          }
          this.videoForm.AssociationMeet = undefined
          this.visible_upVideo = false
          this.$refs.content_ruleForm.resetFields();
          this.videoForm.specialistSwitch = 0
          this.selectHosList = []
          this.selectHosListVideo = []
          this.selectHosList_page_no = 1
          this.selectHosList_title = ''
          this.videoForm.specialist = []
          this.videoForm.keyName = ''
          this.html = '';
          // this.CaseLiveList = []
          // this.CaseLiveList_page_no = 1
          // this.CaseLiveList_title = ''
        } else {
          this.$message.warning("表单有空~")
          return false;
        }
      })
    },
    //修改
    async restDianVode(data) {
      const response = await putVodsList(data)
      if(response.code === 0){
        this.$message.success('修改成功~')
        // if(!data.columnList){
        // //  栏目有一个
        //   let datas = {
        //     id:data.id,
        //     type:'COLUMN'
        //   }
        //   await delVod(datas)
        // }
        await this.getList()
        this.tableListId = ''
      }else {
        this.$message.warning(response.message)
      }
    },
    //新增
    async addDianVode(data) {
      const response = await postVodsList(data)
      if(response.code === 0){
        this.$message.success('新增成功~')
        //获取id,调取接口  vodId - 新增专题的id,conventionId -- 会议id
        // await this.AssociationMeetChange(response.data,this.videoForm.AssociationMeet)
        await this.getList()
      }else {
        this.$message.warning(response.message)
      }
    },
    //关联会议
    async AssociationMeetChange(vodId,conventionId) {
      const response = await post_lead_vods_two(vodId,conventionId)
      if (response.code === 0) {
        this.$message.success('关联成功~')
      } else {
        this.$message.warning(response.message)
      }
    },

    async content_surface_plot_image() {
      let form =  this.videoForm;
      let _this = this;
      let inputDOM = this.$refs.content_surface_plot_original_image.files[0];
      //判断图片大小
      // if (inputDOM.size < 2097152) {
        //判断图片尺寸
        // if (inputDOM) {
        //   var reader = new FileReader()
        //   reader.onload = function (event) {
        //     var base64 = event.target.result
        //     var img = document.createElement('img')
        //     img.src = base64
        //     img.onload = async function () {
        //       if (img.width <= 1920) {
                const image = await update(inputDOM)
                if (image.code === 0) {
                  form.surface_plot = image.data.url
                  _this.$message.success("上传成功")
                } else {
                  _this.$message.warning(image.message)
                }
        //       } else {
        //         _this.$message.warning("注：建议尺寸1920px*520px，宽度不能超过1920px !")
        //       }
        //     }
        //   }
        //   reader.readAsDataURL(inputDOM)
        // }
      // } else {
      //   this.$message.warning("请上传小于2M的图片！")
      // }
      document.getElementById('up_img_surface_plot').value = null;
    },
    async content_surface_plot_original_video() {
      let form = this.videoForm;
      let _this = this;
      let inputDOM = this.$refs.content_surface_plot_video_image.files[0];
      //判断图片大小
      // if (inputDOM.size < 2097152) {
      //
      //   //判断图片尺寸
      //   if (inputDOM) {
      //     var reader = new FileReader()
      //     reader.onload = function (event) {
      //       var base64 = event.target.result
      //       var img = document.createElement('img')
      //       img.src = base64
      //       img.onload = async function () {
      //         if (img.width <= 1920) {
                const image = await update(inputDOM)
                if (image.code === 0) {
                  form.coverUrl = image.data.url
                  _this.$message.success("上传成功")
                } else {
                  _this.$message.warning(image.message)
                }
      //         } else {
      //           _this.$message.warning("注:建议尺寸1920px*1080px，宽度不能超过1920px !")
      //         }
      //       }
      //     }
      //     reader.readAsDataURL(inputDOM)
      //   }
      // } else {
      //   this.$message.warning("请上传小于2M的图片！")
      // }
      document.getElementById('up_img_surface_plot_original').value = null;
    },
    openSwitchBtn(isOpen){
      if(!isOpen){
        this.videoForm.specialist = []
      }
      this.videoForm.specialistSwitch = isOpen
    },

    //富文本编辑器
    create_editor_config(){
      this.$nextTick(() => {
        //等待DOM加载完毕
        const editor =  new E(this.$refs.editor)
        this.editor = editor
        //去除图片视频上传
        editor.config.editor_menus = [
          'image',
          'video'
        ]
        // 设置编辑区域高度为 500px
        editor.config.height =200
        //创建编辑器
        editor.create()
      })
    },
    rulesFun(){
      //大会新增修改对话框中的栏目不必填，app必填
      if(this.tabValue === '1'){ //大会
        this.content_video_rules = {
          surface_plot: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
          coverUrl: [{ required: true, message: '详情封面图', trigger: 'blur' }],
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          // specialist: [{ required: true, message: '请选择栏目', trigger: 'blur' }],
          include: [{ required: true, message: '请输入介绍', trigger: 'blur' }],
        }
      }else if(this.tabValue === '2'){  //app
        this.content_video_rules = {
          surface_plot: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
          coverUrl: [{ required: true, message: '详情封面图', trigger: 'blur' }],
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          specialist: [{ required: true, message: '请选择栏目', trigger: 'blur' }],
          include: [{ required: true, message: '请输入介绍', trigger: 'blur' }],
        }
      }
    },
    newProject(){
      this.html = '';
      this.onCreated();
      this.rulesFun()
      //获取关联会议列表
      this.getHosVideoList()
      this.vodTitle = '新增专题'
      //大会新增时，默认显示关联会议
      this.videoForm.AssociationMeet = this.liveId[0]
      if(this.tabValue === '2'){ //只有栏目下有
        this.videoForm.specialist = this.selectId
      }
      this.visible_upVideo = true;
      // this.$nextTick(() => {
      //   if (this.editor === null) {
      //     this.create_editor_config()
      //   } else {
      //     this.editor.destroy()
      //     this.create_editor_config()
      //   }
      // })
    },
    newVideo(){
      if(this.tabValue === '1'){
        this.videoTitleVod = '新增单视频--大会视频'
      }else {
        this.videoTitleVod = '新增单视频--app'
      }
      this.visible_add_video = true
    },
    close_add_video(){
      this.data_videoVod = []
      this.visible_add_video = false
      this.keyWordVodIsShow = false
      this.search_model = ''
      this.videoPageSizeLive = 10
      this.videoPageNoLive = 1
      this.videoCountLive = 0
    },
    titleBtn(id){
      this.vodId = id
      //获取医学会议列表
      this.getHosList()
      this.visible_Check_video = true

      //获取表格列表数据
      this.getZhuanTableList()
    },
    async getZhuanTableList() {
      let data = {
        parentId:this.vodId,
        itemtitle:this.titleVal,
        page_no:this.vodPageNo,
        page_size:this.vodPageSize
      }
      const response = await getVodsTopic(data)
      if (response.code === 0) {
        this.data_model = response.data
        this.data_model_count = response.count
      } else {
        this.$message.warning(response.message)
      }
    },

    videoPageNoChange(pageNo){

    },
    videoPageNoChange1(pageNo){
      this.videoPageNo = pageNo
      if(this.confirmChoices === '视频库'){
        this.onSearch_model1(this.confirmChoicesValue)
      }else if(this.confirmChoices === '文章'){
        this.onSearch_article(this.confirmChoicesValue)
      }
    },
    videoPageSizeChange1(pageNo,pageSize){
      this.videoPageSize = pageSize
      if(this.confirmChoices === '视频库'){
        this.onSearch_model1(this.confirmChoicesValue)
      }else if(this.confirmChoices === '文章'){
        this.onSearch_article(this.confirmChoicesValue)
      }
    },

    close_Check_video(){
      this.selectHosList = []
      this.selectHosList_page_no = 1
      this.selectHosList_title = ''
      this.selectHosModel = undefined
      this.search_model = ''
      this.article = ''
      this.videoNum = 0
      this.data_videoVod = []
      this.data_video = []
      this.data_model = []
      this.visible_Check_video = false

      this.videoPageNo = 1
      this.videoPageSize = 10
      this.vodPageNo = 1
      this.vodPageSize = 10
      this.confirmChoices = ''


    },
    onsubmit_Check_video(){
      this.selectHosList = []
      this.selectHosList_page_no = 1
      this.selectHosList_title = ''
      this.visible_Check_video = false
    },
    async onSearch_model(value) {
     if(value){
       let data = {}
       if(this.tabValue === '1'){
         //大会
         data = {
           //栏目code
           // "column":this.selectId[0],
           //大会id
           conventionId:this.liveId[0],
           title:value,
           page_no:this.videoPageNoLive,
           page_size:this.videoPageSizeLive,
         }
       }else {
         //app
         data = {
           //栏目code
           column:this.selectId[0],
           //大会id
           // "conventionId":this.liveId[0],
           title:value,
           page_no:this.videoPageNoLive,
           page_size:this.videoPageSizeLive,
         }
       }

       const response = await getKeyWordVods(data)
       if (response.code === 0) {
         this.data_videoVod = response.data
         this.videoCountLive = response.count
       } else {
         this.$message.warning(response.message)
       }
     }else {
       this.$message.warning("请先输入关键词在进行搜索~")
     }
    },
    LiveChange(pageNo){
      this.videoPageNoLive = pageNo
      this.onSearch_model(this.search_model)
    },
    LiveChangeSize(pageNo,pageSize){
      this.videoPageSizeLive = pageSize
      this.onSearch_model(this.search_model)
    },
    async onSearch_model1(value) {
      if(value){
        this.confirmChoices = '视频库'
        this.confirmChoicesValue = value
        let data = {
          title:value,
          vodId:this.vodId,
          page_no:this.videoPageNo,
          page_size:this.videoPageSize,
        }
        const response = await getKeyWordVods(data)
        if (response.code === 0) {
          this.data_video = response.data
          this.data_model_count_video = response.count
        } else {
          this.$message.warning(response.message)
        }
      }else {
        this.$message.warning("请先输入关键字再进行搜索~")
      }
    },
    async onSearch_article(value) {
      if(value){
      this.confirmChoices = '文章'
      this.confirmChoicesValue = value

      let data = {
        title:value,
        vodId:this.vodId,
        page_no:this.videoPageNo,
        page_size:this.videoPageSize,
      }
      const response = await getArticleVods(data)
      if (response.code === 0) {
        // this.keyWordArticle = response.data
        this.data_video = response.data
        this.data_model_count_video = response.count
      } else {
        this.$message.warning(response.message)
      }
      }else {
        this.$message.warning("请先输入关键字再进行搜索~")
      }
    },
    onSearch_model_hos(count) {
      this.videoNum = count
      // let conventionId = this.selectHosModel
      // const response = await get_vods_num(conventionId)
      // if (response.code === 0) {
      //   this.videoNum = response.data
      // } else {
      //   this.$message.warning(response.message)
      // }
    },
    onSearchHos(value) {
      this.selectHosModel = value
    },
    openVideo(url){
      window.open(url)
    },
    //确认导入
    async confirmImport() {
      if (this.selectHosModel) {
        const response = await postLeadVods(this.vodId, this.selectHosModel)
        if (response.code === 0) {
          this.$message.success(response.message)
          // 刷新列表
          await this.getZhuanTableList()
        } else {
          this.$message.warning(response.message)
        }
      } else {
        this.$message.warning('请先选择医学会议在进行导入~')
      }
    },
    upDownBtnZhuan(id,moveUpDown){
      this.postVodMoveUpDown(id,moveUpDown)
    },
    //点播专题对话框公共的上移下移
    async postVodMoveUpDown(id,moveUpDown) {
      let data = {
        id:id,
        type:moveUpDown
      }
      const response = await postLeadVodsMoveUpDown(data)
      if (response.code === 0) {
        this.$message.success('操作成功~')
        // 刷新列表
        await this.getZhuanTableList()
      } else {
        this.$message.warning(response.message)
      }
    },
    async confirmChoice(id) {
      if(this.confirmChoices === '视频库'){
        const response = await postChooseVods(this.vodId,id)
        if (response.code === 0) {
          this.$message.success('添加成功~')
          // 刷新列表
          await this.onSearch_model1(this.search_model)
          await this.getZhuanTableList()
        }else if (response.code === -1){
          this.$message.warning('该视频已被添加~')
        }
        else {
          this.$message.warning(response.message)
        }
      }else if(this.confirmChoices === '文章'){
        const response = await postChooseArticle(this.vodId,id)
        if (response.code === 0) {
          this.$message.success('添加成功~')
          // 刷新列表
          await this.onSearch_article(this.article)
          await this.getZhuanTableList()
        }
        else {
          this.$message.warning(response.message)
        }
      }
      //  刷新列表
      await this.getList()
    },
    async insSingleByManageId(id) {
      let data = {}
      if(this.tabValue === '1'){
      //  大会
        data = {
          // columnCode:,
          id:id,
          conventionId:this.liveId[0]
        }
      }else if(this.tabValue === '2'){
      //  app
        data = {
          // columnList:this.selectId,
          singleColumnCode:this.selectId[0],
          id:id,
          // conventionId:this.liveId[0]
        }
      }
      const response = await postInsSingleByManageId(data)
      if (response.code === 0) {
        this.$message.success('添加成功~')
        // 刷新列表
        await this.onSearch_model(this.search_model)
      } else {
        this.$message.warning(response.message)
      }
      await this.getList()
    },
    async confirm_del(id) {
      const response = await delZhuanVods(id)
      if (response.code === 0) {
        this.$message.success('删除成功~')
        // 刷新列表
        await this.getZhuanTableList()
      //  刷新视频或者文章列表
        if(this.confirmChoices === '视频库'){
          await this.onSearch_model1(this.confirmChoicesValue)
        }else if(this.confirmChoices === '文章'){
          await this.onSearch_article(this.confirmChoicesValue)
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    async soldOutContent(record,isRelease) {
      const response = await upItemIsRelease(record.id,isRelease)
      if (response.code === 0) {
        this.$message.success((isRelease ? '上架':'下架') + '成功~')
        // 刷新列表
        await this.getZhuanTableList()
        //  刷新视频或者文章列表
        if(this.confirmChoices === '视频库'){
          await this.onSearch_model1(this.confirmChoicesValue)
        }else if(this.confirmChoices === '文章'){
          await this.onSearch_article(this.confirmChoicesValue)
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    async openUrl(id) {
      const response = await get_video_one_list(id)
      if (response.code === 0) {
        window.open(response.data[0].mediaUrl)
      }else {
        this.$message.warning(response.message)
      }
    },
    async promotions(list) {
      this.promotionId = list.id
      this.subContentType = list.type
      this.promotionFileId = list.fileId
      //获取栏目
      this.getColumnList(list.id)
      //获取社区
      // this.getcommunityList(list.id)
      this.promotionsVisible = true  

      // 小程序推广回显  
      let data = {
        contentId:this.promotionId,
        contentType:'VOD',
        subContentType:this.subContentType,
      }
      const response = await getminaRecommend(data)
      if (response.code === 0) {
        if(response.data[0] && response.data[0].code === "YUN_ICU"){
           this.uniAppData[0].is_open = true
        }else{
          this.uniAppData[0].is_open = false
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    async getcommunityList(id) {
      this.promotionSpin = true
      const response = await getcommunityPromotion(id,this.subContentType)
      if (response.code === 0) {
        if(response.data.length && response.data[0].isOpen){
          // this.communityData[0].is_open = true
          this.appData[0].is_open = true
          // this.communityData[0].code = response.data[0].code
          this.appData[0].code = response.data[0].code
        }else {
          this.appData[0].is_open = false
        }

      } else {
        this.$message.warning(response.message)
      }
      this.promotionSpin = false
    },
    async getColumnList(id) {
      this.promotionSpin = true
      const response = await getColumnVodsList()
      if (response.code === 0) {
        this.appData = response.data
        this.appData.unshift({
          id:1,
          title:'社区首页',
          is_open:false
        })
        await this.getAppList(id)
      } else {
        this.$message.warning(response.message)
      }
      this.promotionSpin = false
    },
    async getAppList(id) {
      const response = await getAppPromotion(id)
      if (response.code === 0) {
        this.columnListApp = response.data
        //对比
        let app = this.appData
        let columnList = this.columnListApp
        app.forEach((a,index) =>{
          a.is_open = columnList.some(b =>{
            return b.code === a.code
          })
          this.$set(app,index,a)
        })
        await this.getcommunityList(id)  //加载app中社区首页的数据
      } else {
        this.$message.warning(response.message)
      }
    },
    promotionsClose(){
      this.promotionsVisible = false
      this.appData = []
      this.promotionId = null
      this.subContentType = null
      this.getList()
    },
    async AppListSwitch(isOpen, row) {
      //推广
      if(isOpen){
        let data = [
          {
            contentId: this.promotionId,//内容id
            contentType: "VOD",//一级内容
            columnCode: row.code,//栏目
            subContentType: this.subContentType//子级内容
          }
        ]
        await this.postAppSwitch(data,row)
      }else { //关闭推广
        await this.delAppSwitch(this.promotionId,row.code,'app',row)
      }
    },
    async delAppSwitch(id,code,type,row) {

      let data = {
        code:code,
        type:type
      }
      const response = await delAppPromotion(id,data)
      if (response.code === 0) {
        //更新数据
        this.appData.forEach((a,index) => {
          if (a.code === row.code) {
            this.$set(this.appData, index, a)
            return
          }
        })
        this.$message.success("关闭成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async postAppSwitch(data,row) {
      const response = await postAppPromotion(data)
      if (response.code === 0) {
        //更新数据
        this.appData.forEach((a,index) => {
          if (a.code === row.code) {
            this.$set(this.appData, index, a)
            return
          }
        })
        this.$message.success("推广成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async postcommunitySwitch(data,row) {
      const response = await postcommunityPromotion(data)
      if (response.code === 0) {
        // //更新数据
        // this.communityData.forEach((a,index) => {
        //   if (a.code === row.code) {
        //     this.$set(this.communityData, index, a)
        //     return
        //   }
        // })
        this.$message.success("推广成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async communityListSwitch(isOpen, row) {
      //推广
      if (isOpen) {
        let data = {
          type:this.subContentType,//内容类型
          contentId:this.promotionId,
          isOpen:1, //是否启用：0：否 1：是
          isTop:0,
          detailLink:communityLinks(this.subContentType,this.promotionId,this.promotionFileId),//详情链接
        }
        await this.postcommunitySwitch(data, row)
      } else { //关闭推广
        // VOD_SINGLE -视频
        //MIX_SET_TOPIC -点播
        await this.delAppSwitch(this.promotionId, this.subContentType, 'community', row)
      }
    },
    typeChange(value){
      this.stateType = value
      this.getList()
    },
    callback(key){
      this.pageNum = 1
      this.pageSize = 10
      if(key === '1'){
        this.getLiveList()
      }else if(key === '2'){
        this.getLeftList()
      }
      // if(this.selectId[0] === 'YUN_ICU' && key === '2'){
      //   this.columns = this.columnsNew
      // }else {
      //   this.columns = this.columnsOne
      // }
    },
    current_times(time) {
      // 转换为式分秒
      let h = parseInt(time / 60 / 60 % 24)
      h = h < 10 ? '0' + h : h
      let m = parseInt(time / 60 % 60)
      m = m < 10 ? '0' + m : m
      let s = parseInt(time % 60)
      s = s < 10 ? '0' + s : s
      // 作为返回值返回
      return h + ':' + m + ':' + s
    },
    ColumnCodeBtn(id){
      this.ColumnSelection = true
      this.manageId = id
    },
    async ColumnBtn() {
      if (this.columnCode) {
        let data = {
          columnList:this.columnCode,
          id:this.manageId,
          conventionId:this.liveId[0]
        }
        const response = await postInsSingleByManageId(data)
        if (response.code === 0) {
          this.$message.success('添加成功~')
          // 刷新列表
          await this.onSearch_model(this.search_model)
          this.ColumnSelection = false
        } else {
          this.$message.warning(response.message)
        }
      } else {
        this.$message.warning("请选择栏目~")
      }
      await this.getList()
    },
    cancelBtn(){
      this.ColumnSelection = false
      this.columnCode = []
    },
    async SwitchBtn(isOpen,id) {
      let data = {
        id: id,
        isOpen: isOpen
      }
      const response = await putVodsList(data)
      await this.getList()
      if (response.code === 0) {
        this.$message.success('修改成功~')
      } else {
        this.$message.warning(response.message)
      }
    },
    async getTitleValue(id) {  //获取关联会议或者栏目的名称
      const response = await getColumnByVodId(id)
      if (response.code === 0) {
        this.associationValue = response.data
        //每次调用前先置空为false
        this.leftTemplete.forEach((item)=>{
          item.isShow = false
        })
        //将获取到的值在列表中置灰
        for (let i=0;i<this.associationValue.length;i++){
          for (let j=0;j<this.leftTemplete.length;j++){
            if(this.associationValue[i].code === this.leftTemplete[j].code){
              this.leftTemplete[j].isShow = true
            }
          }
        }
        // this.associatedColumn = []
        // response.data.forEach((list)=>{
        //   this.associatedColumn.push(list.code)
        // })
      }
    },
    async conventionTitleByVodId(id) {  //获取关联会议或者栏目的名称
      const response = await selConventionTitleByVodId(id)
      if (response.code === 0) {
        this.associationValue = response.data
      }
    },
    async openAssociation(id,title) {
      this.listIdA = id
      if(title === '关联会议'){
        await this.conventionTitleByVodId(id)
        await this.getAssociationMeet()
      }else if(title === '关联栏目'){
       await this.getTitleValue(id)
      }
      this.titleAssociation = title
      this.association = true
    },
    associationCancel(){
      this.association = false
      this.associatedColumn = []
      this.associationMeet = undefined
      this.Association_pageNo = 1
      this.Association_title = ''
    },
    async delAssociation(title, list) { //删除关联的大会或者栏目
      if (title === '关联会议') {
        const response = await delVodConList(this.listIdA,list.id)
        if (response.code === 200) {
          await this.conventionTitleByVodId(this.listIdA)
        } else {
          this.$message.warning(response.message)
        }
      } else if (title === '关联栏目') {
        const response = await delVodColList(this.listIdA,list.code)
        if (response.code === 200) {
          await this.getTitleValue(this.listIdA)
        } else {
          this.$message.warning(response.message)
        }
      }
      await this.getList()
    },
    async colVodUp(id) { //修改点播列表上移（栏目）
      const response = await colVodUp(id)
      if (response.code === 0) {
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    async colVodDown(id) { //修改点播列表下移（栏目）
      const response = await colVodDown(id)
      if (response.code === 0) {
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    async conVodUp(id) { //修改点播列表上移（大会）
      const response = await conVodUp(id)
      if (response.code === 0) {
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    async conVodDown(id) { //修改点播列表下移（大会）
      const response = await conVodDown(id)
      if (response.code === 0) {
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    addSelectLM() { //栏目
      if(this.associatedColumn.length){
        let data = {
          "id":this.listIdA,
          "columnList":this.associatedColumn
        }
        this.associatedColumn = []
        this.LMDH(data)
      }else {
        this.$message.warning('请先选择栏目~')
      }
    },
    addSelectDH(){ //大会
      if(this.associationMeet){
        let data = {
          "id":this.listIdA,
          "conventionIdList":[this.associationMeet]
        }
        this.LMDH(data)
      }else {
        this.$message.warning('请先选择大会~')
      }
    },
    async LMDH(data) {
      const response = await insVodConOrCol(data)
      if (response.code === 200) {
        if(this.titleAssociation === '关联会议'){
          await this.conventionTitleByVodId(this.listIdA)
        }else if(this.titleAssociation === '关联栏目'){
          await this.getTitleValue(this.listIdA)
        }
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    changeAssociationMeet(){
    //  判断选择的大会是否已被选择
    //   this.associationMeet
      this.associationValue.forEach((list)=>{
        if(list.id === this.associationMeet){
          this.$message.warning("该大会已被选择，请重新选择！")
          this.associationMeet = undefined
        }
      })
    },

  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
  .video-top{
    width: 100%;
    height: 80px;
    display: flex;
    padding-top: 2%;
    justify-content: space-around;
    .input{
      width: 65%;
      margin-left: 10px;
    }
  }
  .video-tab{
    width: 100%;
    //height: 60px;
    border-top: 10px solid #f0f2f5;
  }
  .video-content{
    width: 100%;
    min-height: 600px;
    border-top: 10px solid #f0f2f5;
    display: flex;
    .video-content-l{
      width: 20%;
      height: auto;
    }
    .video-content-r{
      width: 80%;
      height: auto;
      border-left: 13px solid #f0f2f5;
      .r-btn{
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .b-btn{
        margin: 10px;
      }
    }
  }
  .uploadImg{
    border: #DDDDDD 2px dashed;
    width: 120px;
    height: 120px;
    background-image: url("../../../assets/add.png");
    background-size:100% 100%;
    background-repeat:no-repeat;
    cursor: pointer;
  }
  .upload_LiveBroadcastL{
    border: #DDDDDD 2px dashed;
    width: 240px;
    height: 65px;
    margin-left: 20px;
    text-align: center;
  }
  .upload_LiveBroadcastX{
    border: #DDDDDD 2px dashed;
    width: 240px;
    height: 135px;
    margin-left: 20px;
    text-align: center;
  }
  .addVideo{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
  }
  .specialCatalog{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    margin-top: 20px;
    font-weight: bold;
    border-top: 1px solid #e7e7e7;
  }
  .child-content{
    margin-top: 15px;
  }
</style>
