
//拼接字符串---直播地址的
//直播会议---会议管理
export function live_room_link(id) {
   return 'https://yunicu-mp.yunicu.com/conference?convention_id='+ id
}
export function live_room_link_case(url) {
   return 'https://yunicu-mp.yunicu.com'+ url
}

//正式头
export function live_link(room,live_meeting_id,convention_id,herder) {
   return herder+'&room='+room+'&conventionId='+convention_id
}
// // 外包测试头部
// export function live_link(room,live_meeting_id,convention_id) {
//    //https://live-h5.we-fire.cn/series/#/chat-online?live_meeting_id=1456&room=1323&convention_id=1001
//    return 'https://live-h5.we-fire.cn/series/#/chat-online?live_meeting_id='+live_meeting_id+'&room='+room+'&convention_id='+convention_id
// }
//分割字符串
export function live_link_id(url) {
   let obj = {}
   if (url.indexOf('?') < 0) return obj
   let arr = url.split('?')
   url = arr[1]
   let array = url.split('&')
   for (let i = 0; i < array.length; i++) {
      let arr2 = array[i]
      let arr3 = arr2.split('=')
      obj[arr3[0]] = arr3[1]
   }
   return obj
}

//推广社区时传递的参数
export function communityLinks(type,vod_id,file_id){
   if(type === 'VOD_SINGLE'){ //点播视频
      return 'http://tool-h5.yunicu.com/#/liveShare?id='+vod_id+'&fileId='+file_id
   }else if(type === 'MIX_SET_TOPIC'){ //专题
      return 'http://tool-h5.yunicu.com/#/videoShare?id='+vod_id
   }else {
      return null
   }
}
